import "./src/styles/main.scss"
import  { littlefoot } from 'littlefoot'

export function onRouteUpdate({ location }) {
    littlefoot({
        hoverDelay: 0,
        dismissDelay: 0,
        buttonTemplate: `
        <a
            aria-controls="fncontent:<% id %>"
            aria-expanded="false"
            aria-label="Footnote <% number %>"
            class="littlefoot-footnote__button littlefoot-footnote__button__ellipsis"
            id="<% reference %>"
            rel="footnote"
            title="See Footnote <% number %>"
        >
            <svg class="ellipsis" viewbox="0 0 31 6" preserveAspectRatio="xMidYMid">
                <circle r="3" cx="3" cy="3" fill="white"></circle>
                <circle r="3" cx="15" cy="3" fill="white"></circle>
                <circle r="3" cx="27" cy="3" fill="white"></circle>
            </svg>
        </a>
        `
    })
}